import { Button, Form, Input } from 'antd';

import { PASSWORD_PATTERN } from 'src/utils/validation/validation-patterns';

export type IUserChangePasswordForm = {
  password: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

type Props = {
  onSubmit: (values: IUserChangePasswordForm) => Promise<void>;
  onCancel: () => void;
  isLoading?: boolean;
};

const UserChangePasswordForm = ({ onCancel, onSubmit, isLoading = false }: Props) => {
  const [form] = Form.useForm<IUserChangePasswordForm>();

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  const handleSubmit = async (values: IUserChangePasswordForm) => {
    await onSubmit(values);
    form.resetFields();
  };

  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit}>
      <Form.Item name="password" label="Password" rules={[{ required: true, message: 'Please input your password' }]}>
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="newPassword"
        label="New password"
        tooltip="The password must be at least 8 characters long, it must contain at least one uppercase letter, at least one digit and at least one special character."
        rules={[
          { required: true, message: 'Please input your new password' },
          {
            pattern: PASSWORD_PATTERN,
            message: 'New password does not meet password policy!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="newPasswordConfirmation"
        label="Confirm new password"
        rules={[
          { required: true, message: 'Please confirm your new password!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) return Promise.resolve();

              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <div className="flex justify-end">
        <Button type="default" onClick={handleCancel} className="mr-2">
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save changes
        </Button>
      </div>
    </Form>
  );
};

export default UserChangePasswordForm;
