import { Fragment } from 'react';
import { Form, Input, Button } from 'antd';

export type IUserConfirmationCodeForm = {
  code: string;
};

type Props = {
  email: string;
  onSubmit: (values: IUserConfirmationCodeForm) => Promise<void>;
  onCancel: () => void;
  isLoading?: boolean;
};

const UserConfirmationCodeForm = ({ email, isLoading, onSubmit, onCancel }: Props) => {
  const [form] = Form.useForm();

  return (
    <Fragment>
      <p>Please check your email ({email}) for the confirmation code.</p>

      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item
          name="code"
          label="Confirmation code"
          rules={[
            {
              required: true,
              message: 'Please input your confirmation code',
            },
          ]}
        >
          <Input type="tel" />
        </Form.Item>

        <div className="flex justify-end">
          <Button type="default" onClick={onCancel} className="mr-2">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Save changes
          </Button>
        </div>
      </Form>
    </Fragment>
  );
};

export default UserConfirmationCodeForm;
