import BillingPage from '../pages/Billing/BillingPage';
import Homepage from '../pages/Homepage/HomePage.tsx';
import OrganizationPage from '../pages/Organization/OrganizationPage';
import Deposits from '../pages/Deposits/Deposits.tsx';
import Integrations from '../pages/Integrations/Integrations.tsx';
import IntegrationAuth from '../pages/Integrations/IntegrationAuth/IntegrationAuth.tsx';
import Migrations from '../pages/Migrations/Migrations.tsx';
import Support from '../pages/Support/Support.tsx';
import Impersonate from '../pages/Impersonate/Impersonate.tsx';

import type { RouteObject } from 'react-router';

export const mainRoutes: RouteObject = {
  children: [
    {
      index: true,
      path: '/',
      element: <Homepage />,
    },
    {
      path: 'organization',
      element: <OrganizationPage />,
    },
    {
      path: 'deposits',
      element: <Deposits />,
    },
    {
      path: 'integrations',
      element: <Integrations />,
    },
    {
      path: 'integrations/auth/:provider',
      element: <IntegrationAuth />,
    },
    {
      path: 'migrations',
      element: <Migrations />,
    },
    {
      path: 'billing',
      element: <BillingPage />,
    },
    {
      path: 'support',
      element: <Support />,
    },
    {
      path: 'impersonate/:userId',
      element: <Impersonate />,
    },
  ],
};
