import { Button, Form, Input } from 'antd';

export type IUserTwoFAForm = {
  code: number;
};

type Props = {
  onSubmit: (values: IUserTwoFAForm) => void;
  onCancel: () => void;
  isLoading: boolean;
  submitText?: string;
};

const UserTwoFAForm = ({ onSubmit, onCancel, isLoading, submitText = 'Continue' }: Props) => {
  return (
    <Form onFinish={onSubmit} layout="vertical">
      <Form.Item
        label="Enter the 6-digit code generated by your authenticator app."
        name="code"
        required
        rules={[{ required: true, message: 'Please add the code!' }]}
      >
        <Input placeholder="Add your code" type="number" size="large" className="ck-input--no-controls" />
      </Form.Item>

      <div className="flex justify-end">
        <Button type="default" onClick={onCancel} className="mr-2">
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          {submitText}
        </Button>
      </div>
    </Form>
  );
};

export default UserTwoFAForm;
