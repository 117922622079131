import { UserInfoContainer } from 'src/containers/Profile';
import { usePageMeta } from 'src/hooks';

export default function Profile() {
  usePageMeta('Profile');

  return (
    <div className="max-w-[535px] mx-auto">
      <UserInfoContainer />
    </div>
  );
}
