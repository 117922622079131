import { Form, Input, Button } from 'antd';

export type IUserChangeEmailForm = {
  email: string;
};

type Props = {
  email: string;
  onSubmit: (values: IUserChangeEmailForm) => Promise<void>;
  onCancel: () => void;
  isLoading?: boolean;
};

const UserChangeEmailForm = ({ email, onSubmit, onCancel, isLoading = false }: Props) => {
  const [form] = Form.useForm();

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <Form.Item
        name="email"
        label="New email address"
        rules={[
          { required: true, message: 'Please input new email address!' },
          { type: 'email', message: 'Email address is invalid' },
          () => ({
            validator(_, value) {
              if (!value || email !== value) return Promise.resolve();

              return Promise.reject(new Error('Email address has not changed!'));
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>

      <div className="flex justify-end">
        <Button type="default" onClick={onCancel} className="mr-2">
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Change
        </Button>
      </div>
    </Form>
  );
};

export default UserChangeEmailForm;
