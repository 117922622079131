import { Fragment } from 'react';

import { HomeContainer, MigrationAlertContainer } from 'src/containers/Home';
import { Main } from 'src/components/Layout';
import { withSuspense } from 'src/hoc/withSuspense';
import { NotificationContainer } from 'src/containers/Notification';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

import { ContinuityBanner } from './ui';

const HomePage = () => {
  return (
    <Main
      title="Home"
      headerChildren={
        <Fragment>
          <ContinuityBanner />
          <MigrationAlertContainer />
        </Fragment>
      }
    >
      <div className="container mx-auto">
        <HomeContainer />

        <Card>
          <CardHeader>
            <CardTitle>Latest in your Organization</CardTitle>
          </CardHeader>
          <CardContent>
            <NotificationContainer interacted={false} />
          </CardContent>
        </Card>
      </div>
    </Main>
  );
};

export default withSuspense(HomePage);
