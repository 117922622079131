import { Button } from 'antd';

import styles from './ProfileInfoRow.module.scss';

type Props = {
  label: string;
  value: string;
  onClick: () => void;
};

const ProfileInfoRow = ({ label, value, onClick }: Props) => {
  return (
    <div className={styles.row}>
      <div>{label}</div>
      <div className={styles.wrapper}>
        <strong className="truncate">{value}</strong>
        <div className={styles.action}>
          <Button type="link" onClick={onClick}>
            Change
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfoRow;
