import { Navigate, useSearchParams } from 'react-router';
import { Col, Row, Tabs } from 'antd';
import { useSuspenseQuery, useBackgroundQuery } from '@apollo/client';

import { BillingDocument, CompanyDocument } from 'src/graphql/schema';
import { Main } from 'src/components/Layout';
import {
  BillingInvoicesContainer,
  BillingInformationContainer,
  BillingSubscriptionsContainer,
  BillingPaymentContainer,
  BillingCancelationPlanContainer,
} from 'src/containers/Billing';
import { BillingExploreDetails, BillingCard } from 'src/components/Billing';
import { withSuspense } from 'src/hoc';
import { CancelPlanBanner } from 'src/components/Billing/Cancel/Plan';
import { CancelationPlanContextProvider } from 'src/context/billing';
import { usePageMeta } from 'src/hooks';

import type { TabsProps } from 'antd';
import type { IBillingQuery, ICompanyQuery } from 'src/graphql/schema';

const tabKeys = ['plan-details', 'payment-info'] as const;

const BillingPage = () => {
  usePageMeta('Billing');
  const { data, refetch: refetchCompany } = useSuspenseQuery<ICompanyQuery>(CompanyDocument);
  const [queryRef, { refetch: refetchBilling }] = useBackgroundQuery<IBillingQuery>(BillingDocument);
  const [searchParams, setSearchParams] = useSearchParams();

  const tabParam = searchParams.get('t') as (typeof tabKeys)[number];
  const activeTab = tabParam && tabKeys.includes(tabParam) ? tabParam : 'plan-details';

  const refetch = () => {
    Promise.all([refetchCompany(), refetchBilling()]);
  };

  if (!data?.company.updateableBilling) return <Navigate to="/" />;

  const items: TabsProps['items'] = [
    {
      key: 'plan-details',
      label: 'Plan Details',
      children: (
        <CancelationPlanContextProvider
          exclusiveOfferApplied={Boolean(data.company.subscriptionExclusiveCouponAppliedAt)}
          cancellationRequested={Boolean(data.company.subscriptionCancellationRequestedAt)}
        >
          <div className="billing-container">
            {data.company.subscriptionCancellationRequestedAt && <CancelPlanBanner />}
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <BillingSubscriptionsContainer
                  queryRef={queryRef}
                  subscriptionExclusiveCouponAppliedAt={data.company.subscriptionExclusiveCouponAppliedAt}
                />
              </Col>

              <Col span={12}>
                <BillingCard title="Explore other plans" className="mb-4">
                  <BillingExploreDetails />
                </BillingCard>
              </Col>
            </Row>
          </div>

          <BillingCancelationPlanContainer refetch={refetch} />
        </CancelationPlanContextProvider>
      ),
    },
    {
      key: 'payment-info',
      label: 'Payment Info',
      children: (
        <div className="billing-container">
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <BillingCard title="Payment Method" className="mb-4">
                <BillingPaymentContainer queryRef={queryRef} />
              </BillingCard>
              <BillingCard title="Billing Information" className="mb-4">
                <BillingInformationContainer queryRef={queryRef} />
              </BillingCard>
            </Col>

            <Col span={12}>
              <BillingCard title="Invoice History">
                <BillingInvoicesContainer />
              </BillingCard>
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  const onChange = (key: string) => setSearchParams({ t: key });

  return (
    <Main title="Billing" className="billing" noPaddingOnContent mainContentClassName="flex">
      <Tabs className="ck-billing__tabs" items={items} activeKey={activeTab} onChange={onChange} />
    </Main>
  );
};

export default withSuspense(BillingPage);
